import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AppBar,
  Box,
  Divider,
  Fab,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material'
import {
  AddAPhotoRounded,
  ArrowBackRounded,
  CheckCircleRounded,
  FavoriteBorderRounded,
  MoreVertRounded
} from '@mui/icons-material'
// Components
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import ShareLargeButton from '../../uicomponents/ShareLargeButton'
// Styles
import { Icon, StopContainer } from './styles'
// Actions and functions
import {
  handleClickWebToAppButton,
  setOpenAlertInformation,
  setOpenStopOptions,
  setStopArrivals,
  setStopImage,
  setStopPictures,
  setStopSelected,
  verifyStop
} from '../../actions/ui'
import { logEvent } from '../../firebase/firebase-config'
// Images
import streetViewIcon from '../../img/stop_option_street-view.png'
import reportIcon from '../../img/stop_option_report.png'
import addPhotoIcon from '../../img/stop_option_add_photo.png'
import editIcon from '../../img/stop_option_edit.png'
import { WarningIcon } from '../Desktop/Stop/styles'
import { Photo } from '../../uicomponents/mobile/stop/Photo'
import { Photos } from '../../uicomponents/mobile/stop/Photos'
import { Arrivals } from '../../uicomponents/desktop/stop/Arrivals'
import { Quiz } from '../../uicomponents/desktop/stop/Quiz'
import { Security } from '../../uicomponents/desktop/stop/Security'
import { Reports } from '../../uicomponents/desktop/stop/Reports'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import { OccupancyLevel } from '../../uicomponents/desktop/stop/OccupancyLevel'
import { MorePictures } from '../../uicomponents/desktop/stop/MorePictures'
import { AlertInformation } from '../../uicomponents/desktop/alerts/AlertInformation'
import { theme } from '../../theme/theme'
import { Layout } from '../../uicomponents/layouts/Layout'
import { ArrivalTime } from '../../uicomponents/desktop/stop/ArrivalTime'
import { Information } from '../../uicomponents/desktop/stop/Information'
import { getArrivalsOfRealtimeHub, getArrivalsOfThirdParties } from '../../actions/map'
import { CustomMap } from '../../uicomponents/Map'
import UserProfilePreview from '../Desktop/UserProfilePreview'
import { REACT_APP_HOST, REACT_APP_SHOW_LOGIN, REACT_APP_SHOW_STOP_SECURITY_SURVEY } from '../../constants/config'

const Stop = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')
  const queries = useParams()

  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const user = useSelector(state => state?.user?.auth)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const arrivalsGrouped = useSelector(state => state?.ui?.arrivals)
  const showETA = useSelector(state => state.ui.showETA)

  const [value, setValue] = useState(0)
  const [openPhoto, setOpenPhoto] = useState(false)
  const [reports, setReports] = useState([])

  useEffect(() => {
    if (!user) return
    resetStopData()
  }, [pathname, queries, user])

  useEffect(() => {
    if (!stopSelected) return
    setReports(stopSelected?.reports_affected)
  }, [stopSelected])

  useEffect(() => {
    // Every 30 seconds, setInterval method calls updateArrivalsRealtime function and updates the real time of stop arrivals
    const interval = setInterval(() => updateArrivalsRealtime(arrivalsGrouped, stopSelected, cityConfig), 30000)

    return () => {
      clearInterval(interval)
    }
  }, [arrivalsGrouped])

  const handleClosePhoto = () => setOpenPhoto(false)

  const handleOpenPhoto = () => setOpenPhoto(true)

  const resetStopData = () => {
    dispatch(setStopSelected(null))
    dispatch(setStopArrivals(null))
    dispatch(setOpenAlertInformation(false))
    setValue(0)
    setReports([])

    dispatch(verifyStop())
  }

  /** updateArrivalsRealtime()
   * @description updates arrivals real time
   * @param { array } arrivalsGrouped - current stop arrivals grouped list
   * @param { object } stopSelected - current stop selected
   * @param { object } cityConfig - current city config
   */

  const updateArrivalsRealtime = (arrivalsGrouped = [], stopSelected, cityConfig) => {
    if (!cityConfig || !stopSelected || arrivalsGrouped?.length === 0) return

    const urlParams = {
      city_id: Number(pathname?.split('/')[2]), stop_id: pathname?.split('/')[3]
    }

    if (cityConfig.config?.realtime_hub_enabled) {
      dispatch(getArrivalsOfRealtimeHub(cityConfig, stopSelected, arrivalsGrouped))
    } else if (cityConfig.config?.realtime_arrivals_enabled) {
      dispatch(getArrivalsOfThirdParties(cityConfig, stopSelected, arrivalsGrouped, urlParams))
    } else {
      const currentArrivals = arrivalsGrouped.map(arrivalGroup => {
        arrivalGroup.arrival.isLoading = false
        return arrivalGroup
      })
      dispatch(setStopArrivals(currentArrivals))
    }
  }

  if (!user) {
    return <SkeletonApp />
  }

  return (
    <Layout>
      <StopContainer
        mobile={mobile}
      >
        {mobile &&
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              zIndex: 401,
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {showETA && <ArrivalTime />}
            {REACT_APP_SHOW_STOP_SECURITY_SURVEY && <Security />}
          </div>}
        {mobile
          ? <PhotoGallery
              handleClosePhoto={handleClosePhoto}
              handleOpenPhoto={handleOpenPhoto}
            />

          : <>
            {REACT_APP_SHOW_LOGIN &&
              <UserProfilePreview />}
            {stopSelected &&
              <Box
                sx={{
                  position: 'absolute',
                  top: '32px',
                  left: '480px',
                  zIndex: 402,
                  backgroundColor: 'transparent',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <ShareLargeButton
                  eventName='stop_share_clicked'
                  eventParams={{
                    user_id: user?.uid,
                    os: 'web',
                    user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                    user_gender: userData ? userData?.gender : null,
                    user_lat: userPosition ? userPosition.lat : null,
                    user_lng: userPosition ? userPosition.lng : null,
                    city_id: cityConfig?.city_id,
                    stop_lat: stopSelected?.stop_lat,
                    stop_lng: stopSelected?.stop_lon,
                    stop_address: stopSelected?.stop_name,
                    stop_transport_type_id: stopSelected?.transport_type?.transport_type_id,
                    stop_transportation_type_name: stopSelected?.transport_type?.name,
                    stop_code: stopSelected?.stop_code
                  }}
                  params={{
                    link: `${REACT_APP_HOST}/stops/${cityConfig?.city_id}/${arrivalsGrouped?.length >= 1 ? arrivalsGrouped[0]?.arrivals[0]?.trip?.route?.transport_type?.transport_type_id : 0}/${stopSelected?.stop_id}?invitedby=${user?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=stop_share_clicked`,
                    title: t('share.share_stop_preview_title'),
                    description: `${stopSelected?.stop_name}`
                  }}
                  shareText={t('share.title')}
                  shareType='share'
                />
                <ShareLargeButton
                  eventName='send_to_phone'
                  eventParams={{
                    user_id: user?.uid,
                    os: 'web',
                    user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                    user_gender: userData ? userData?.gender : null,
                    user_lat: userPosition ? userPosition.lat : null,
                    user_lng: userPosition ? userPosition.lng : null,
                    city_id: cityConfig?.city_id,
                    stop_lat: stopSelected?.stop_lat,
                    stop_lng: stopSelected?.stop_lon,
                    stop_address: stopSelected?.stop_name,
                    stop_transport_type_id: stopSelected?.transport_type?.transport_type_id,
                    stop_transportation_type_name: stopSelected?.transport_type?.name,
                    stop_code: stopSelected?.stop_code,
                    from: 'stop'
                  }}
                  params={{
                    link: `${REACT_APP_HOST}/stops/${cityConfig?.city_id}/${arrivalsGrouped?.length >= 1 ? arrivalsGrouped[0]?.arrivals[0]?.trip?.route?.transport_type?.transport_type_id : 0}/${stopSelected?.stop_id}?invitedby=${user?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=stop_share_clicked`,
                    title: t('share.share_stop_preview_title'),
                    description: stopSelected?.stop_name
                  }}
                  notificationBody={{
                    title: t('share.share_stop_preview_title'),
                    content: t('send_to_mobile.address_prompt', { address: stopSelected?.stop_name })
                  }}
                  shareText={t('send_to_mobile.title')}
                  shareType='send-to-mobile'
                />
              </Box>}
            <CustomMap showDraggableDestinationMarkers={false} />
            <MorePictures />
          </>}
        <Box
          sx={{
            height: 'calc(100% - 32px)',
            position: 'absolute',
            top: '16px',
            left: '16px',
            zIndex: '400',
            width: '450px',

            backgroundColor: '#fff',
            borderRadius: '16px',
            boxSizing: 'border-box',
            boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px',
            '@media (max-width:480px)': {
              height: '100%',
              color: '#fff',
              position: 'relative',
              top: 0,
              left: 0,
              width: '100%',

              borderRadius: 0,
              boxShadow: 'none'
            }
          }}
        >
          <ActionBar setValue={setValue} />
          <TabsBar
            value={value}
            setValue={setValue}
            pathname={pathname}
            dispatch={dispatch}
            handleOpenPhoto={handleOpenPhoto}
            reports={reports}
          />
        </Box>

        {/* Drawers */}
        <Photo
          openPhoto={openPhoto}
          handleClosePhoto={handleClosePhoto}
        />
        <SendToPhoneModal />
        <ShareModal />
        <OccupancyLevel />
        <AlertInformation />
      </StopContainer>
    </Layout>
  )
}

export default Stop

const ActionBar = ({ setValue }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const disabled = false

  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const photoGallery = useSelector(state => state?.ui?.components?.openMoreStopPictures)

  const handleClickBack = () => {
    if (photoGallery?.enabled) {
      dispatch(setStopPictures({ enabled: false }))
    } else {
      setValue(0)
      navigate(-1)
    }
  }

  const handleClickMenu = () => {
    dispatch(setOpenStopOptions(true))
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 8px 0 8px',
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',
        height: '50px',
        '@media (max-width:480px)': {
          backgroundColor: theme.palette.primary.main,
          padding: '0px 5px',
          borderRadius: 0
        }
      }}
    >
      <IconButton
        onClick={handleClickBack}
        disabled={!stopSelected}
      >
        <ArrowBackRounded
          sx={{
            color: theme.palette.icons.primary,
            '@media (max-width:480px)': {
              color: '#fff'
            }
          }}
        />
      </IconButton>

      <Box
        sx={{
          overflow: 'hidden', width: '100%'
        }}
      >
        {stopSelected?.stop_name
          ? (<Typography
              sx={{
                color: '#222831',
                fontSize: '16px',
                margin: '0 0 0 8px',
                '@media (max-width:480px)': {
                  color: '#fff',
                  margin: 0,
                  animation: 'scroll-left 8s linear infinite',
                  whiteSpace: 'nowrap',
                  '@keyframes scroll-left': {
                    '0%': {
                      transform: ' translateX(100%)'
                    },
                    '100%': {
                      opacity: 1, transform: ' translateX(-100%)'
                    }
                  }
                }
              }}
              variant='h1'
             >
            {stopSelected?.stop_name}
          </Typography>)
          : (<Skeleton
              variant='text'
              width='100%'
             />)}
      </Box>

      {!photoGallery?.enabled && disabled && <IconButton>
        <FavoriteBorderRounded
          sx={{
            color: theme.palette.icons.primary,
            '@media (max-width:480px)': {
              color: '#fff'
            }
          }}
        />
      </IconButton>}

      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={handleClickMenu}
        >
          <MoreVertRounded
            sx={{
              color: theme.palette.icons.primary,
              '@media (max-width:480px)': {
                color: '#fff'
              }
            }}
          />
        </IconButton>
        <StopMenu />
      </Box>

    </Box>
  )
}

const StopMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const mobile = useMediaQuery('(max-width:480px)')

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]), stop_id: pathname?.split('/')[3]
  }

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const open = useSelector(state => state?.ui?.components?.stopOptions)
  const user = useSelector(state => state?.user?.auth)

  const options = [{
    name: t('stop.options.stop_details_option_show_in_street_view'),
    shortcut: 'stop_street_view',
    color: '#EBF8E5',
    icon: streetViewIcon
  }, {
    name: t('stop.options.report'), shortcut: 'stop_report', color: '#E8EEF7', icon: reportIcon
  }, {
    name: t('stop.options.stop_details_option_add_picture'),
    shortcut: 'stop_picture_take',
    color: '#E8F7F7',
    icon: addPhotoIcon
  }, {
    name: t('stop.options.edit'), shortcut: 'stop_edit', color: '#F7F2E8', icon: editIcon
  }]

  const handleClose = () => dispatch(setOpenStopOptions(!open))

  const handleOpen = () => dispatch(setOpenStopOptions(true))

  const handleClick = (option) => {
    dispatch(setOpenStopOptions(false))

    dispatch(handleClickWebToAppButton(true, {
      from: option?.shortcut,
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: user.uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString() || urlParams.city_id.toString(),
      link_to: `${REACT_APP_HOST}/stops/${urlParams.city_id}/${urlParams.stop_id}`
    }, logEvent))
  }

  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        dispatch(setOpenStopOptions(false))
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [open])

  return (
    <>
      {mobile
        ? (<SwipeableDrawer
            anchor='bottom'
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            disableEnforceFocus
            disableSwipeToOpen
            sx={{
              '& .MuiPaper-root': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 0,
                backgroundColor: '#ffffff',
                borderRadius: '14px 14px 0 0',
                boxSizing: 'border-box'
              }
            }}
           >
          <Box sx={{ width: '100%', position: 'relative' }}>
            <Divider
              sx={{
                height: '4px',
                width: '24px',
                backgroundColor: 'rgb(192,188,191)',
                borderRadius: '2px',
                position: 'absolute',
                top: '8px',
                left: 'calc(50vw - 12px)'
              }}
            />
            <Options
              options={options}
              handleClick={handleClick}
            />
          </Box>
        </SwipeableDrawer>)
        : (<Box
            sx={{
              display: open ? 'block' : 'none',
              position: 'absolute',
              right: 0,
              zIndex: '400',
              background: '#fff',
              minWidth: '226px',
              borderRadius: '16px',
              boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px'
            }}
            ref={ref}
           >
          <Options
            options={options}
            handleClick={handleClick}
          />
        </Box>)}
    </>
  )
}

const Options = ({ options, handleClick }) => {
  return (
    <List
      sx={{
        margin: 0,
        padding: '18px 8px',
        '@media (max-width:480px)': {
          padding: '32px 16px 16px 16px'
        }
      }}
    >
      {options?.map((option) => {
        return (
          <ListItem
            sx={{
              textTransform: 'none',
              fontWeight: 400,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              padding: 0
            }}
            key={option?.shortcut}
          >
            <ListItemButton
              sx={{ borderRadius: '8px' }}
              onClick={() => handleClick(option)}
            >
              <ListItemIcon
                sx={{
                  minWidth: '32px',
                  width: '32px',
                  height: '32px',
                  borderRadius: '8px',
                  backgroundColor: option?.color,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '16px'
                }}
              >
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <Icon src={option?.icon} />
                </ListItemIcon>
              </ListItemIcon>

              <ListItemText
                sx={{
                  margin: 0, fontSize: '16px', whiteSpace: 'nowrap'
                }}
              >
                {option?.name}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={{
        overflow: 'auto',
        background: '#fafafa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        position: 'relative'
      }}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </Box>
  )
}

const TabsBar = ({ value, setValue, handleOpenPhoto, reports, showBar, setShowBar }) => {
  const { t } = useTranslation()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const showETA = useSelector(state => state.ui.showETA)
  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const arrivals = useSelector(state => state?.ui.arrivals)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const user = useSelector(state => state?.user?.auth)

  const [feedbackObj, setFeedbackObj] = useState()

  useEffect(() => {
    if (stopSelected && arrivals && arrivals.length > 0) {
      const feedback = {
        city_id: cityConfig?.city_id,
        question_id: 3,
        stop: {
          stop_id: stopSelected?.stop_id,
          stop_code: stopSelected?.stop_code,
          stop_lat: stopSelected?.stop_lat,
          stop_lon: stopSelected?.stop_lon,
          stop_name: stopSelected?.stop_name,
          trips: arrivals?.map((trip) => {
            return {
              trip_id: trip.arrival.trip_id,
              arrival_time: trip.arrival.arrival_time,
              stop_sequence: trip.arrival.stop_sequence,
              route_id: trip.arrival.trip.route_id,
              service_id: trip.arrival.trip.service_id,
              shape_id: trip.arrival.shape_id,
              trip_headsign: trip.arrival.trip.trip_headsign,
              route_short_name: trip.arrival.trip.route.route_short_name,
              route_long_name: trip.arrival.trip.route.route_long_name,
              route_desc: trip.arrival.trip.route.route_desc,
              agency_id: trip.arrival.trip.route.agency_id,
              route_type: trip.arrival.trip.route.route_type
            }
          })
        }
      }
      setFeedbackObj(feedback)
    }
  }, [stopSelected, arrivals])

  const handleChange = (newValue) => {
    setValue(newValue)

    if (newValue === 1) {
      // see_stop_reports event is sent when the status tab is selected.
      logEvent('see_stop_reports', {
        stop_id: stopSelected?.stop_id,
        stop_lat: stopSelected?.stop_lat,
        stop_lng: stopSelected?.stop_lon,
        stop_address: stopSelected?.stop_name,
        stop_transport_type_id: stopSelected?.transport_type?.transport_type_id,
        stop_transportation_type_name: stopSelected?.transport_type?.name,
        city_name: cityConfig?.name,
        lat: userPosition?.lat || null,
        lng: userPosition?.lng || null,
        user_id: user?.uid,
        os: 'web',
        city_id: cityConfig?.city_id.toString()
      })
    }
  }

  return (
    <Box
      sx={{
        padding: 0,
        boxSizing: 'border-box',
        height: 'calc(100% - 50px)'
      }}
    >
      <AppBar
        sx={{ height: '48px' }}
        elevation={0}
        position='static'
        color='transparent'
      >
        <Tabs
          value={value}
          onChange={(e, newValue) => handleChange(newValue)}
          variant='fullWidth'
          aria-label='stop information options'
          TabIndicatorProps={{
            style: {
              backgroundColor: useMediaQuery('(max-width:480px)') ? '#fff' : theme.palette.primary.main
            }
          }}
          sx={{
            backgroundColor: useMediaQuery('(max-width:480px)') ? theme.palette.primary.main : '#fff'
          }}
        >
          <Tab
            sx={{
              minHeight: '48px',
              padding: '6px 12px',
              textTransform: 'none',
              '&.MuiTab-textColorPrimary': {
                color: '#000000DE',
                '@media (max-width:480px)': {
                  color: '#BCBEC0'
                }
              },
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                '@media (max-width:480px)': {
                  color: '#fff'
                }
              }
            }}
            label={t('stop.stop_details_tab_title')}
            defaultChecked
          />
          <Tab
            sx={{
              minHeight: '48px',
              textTransform: 'none',
              '&.MuiTab-textColorPrimary': {
                color: '#BCBEC0'
              },
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                '@media (max-width:480px)': {
                  color: '#fff'
                }
              }
            }}
            icon={
              reports?.length >= 1
                ? <WarningIcon sx={{ color: '#f5504f', borderRadius: '18px', backgroundColor: '#ffffff' }} />
                : <CheckCircleRounded
                    sx={{ color: '#5DCD8E', borderRadius: '18px', backgroundColor: '#ffffff' }}
                  />
            }
            iconPosition='start'
            label={t('stop.stop_details_status_tab_title')}
          />
        </Tabs>
      </AppBar>

      {value === 0 &&
        <TabPanel
          value={value}
          index={0}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            boxSizing: 'border-box',
            height: 'calc(100% - 48px)',
            maxHeight: 'calc(100% - 48px)',
            overflow: 'auto'
          }}
        >
          <Photos
            handleOpenPhoto={handleOpenPhoto}
          />

          <Arrivals
            showBar={showBar}
            showETA={showETA}
            feedbackObj={feedbackObj}
            setShowBar={setShowBar}
          />

          {showETA &&
            <Information />}
        </TabPanel>}

      {value === 1 && <TabPanel
        value={value}
        index={1}
        sx={{
          height: 'calc(100% - 48px)',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          '@media (max-width:480px)': {
            height: '100%',
            borderRadius: 0
          }
        }}
                      >
        <Reports
          showBar={showBar}
          reports={reports}
        />
      </TabPanel>}

      <Quiz />
    </Box>
  )
}

const PhotoGallery = ({ handleOpenPhoto }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const pictures = useSelector(state => state?.ui?.components?.openMoreStopPictures?.pictures)
  const open = useSelector(state => state?.ui?.components?.openMoreStopPictures?.enabled)
  const user = useSelector(state => state?.user?.auth)

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]), stop_id: pathname?.split('/')[3]
  }

  const handleClickPhoto = (photo) => {
    handleOpenPhoto()
    dispatch(setStopImage(photo))
    dispatch(setStopPictures({ enabled: false, pictures }))
  }

  return (
    <Box
      sx={{
        display: open ? 'block' : 'none', width: '100%', height: '100%'
      }}
    >
      <ImageList
        sx={{ width: '100vw', margin: 0 }}
        cols={2}
        rowHeight={164}
      >
        {pictures?.map((item) => (<ImageListItem key={item.img} onClick={() => handleClickPhoto(item)}>
          <img
            style={{ width: '100%', height: 164 }}
            src={item?.stop_picture_uri}
            alt={item.title}
            loading='lazy'
          />
        </ImageListItem>))}
      </ImageList>
      <Fab
        onClick={() => dispatch(handleClickWebToAppButton(true, {
          from: 'stop_picture_take',
          lat: userPosition ? userPosition?.lat : null,
          lng: userPosition ? userPosition?.lng : null,
          user_id: user.uid,
          os: 'web',
          city_id: urlParams?.city_id.toString(),
          link_to: `${REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
        }, logEvent))}
        sx={{
          right: '20px',
          bottom: '40px',
          position: 'absolute',
          backgroundColor: theme.palette.primary.main,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: theme.palette.primary.main
          }
        }}
        aria-label='add photo'
      >
        <AddAPhotoRounded />
      </Fab>

    </Box>
  )
}
