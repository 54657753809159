import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST, REACT_APP_VARIANT_ID } from '../constants/config'

export const getTicketValidationUserStats = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()
  // eslint-disable-next-line no-unused-vars
  const reqConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': REACT_APP_VARIANT_ID
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/rewards/tickets/getUserStats?cityId=${cityId}`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting user stats')
      }
    })
    .then((response) => response)
    .catch((err) => {
      console.error(err)
    })

//   return [
//     {
//       id: 1,
//       agency_id: 'A00000006',
//       ticket_sample_picture_url: 'https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/MVPs%2FTickets%2Fticket_example_rapido.png?alt=media&token=2c1394e1-3b20-4c89-8f78-ef2e1adbc186',
//       ticket_valid_id_example: '123456',
//       ticket_valid_regex: '.\\d{6}.',
//       rewards_url: 'https://marketplace.ualabee.com/4-el-r%C3%A1pido-?city_id=31#el-r%C3%A1pido-&custom_token=$CUSTOM_TOKEN&agency_id=A00000006',
//       agency_name: 'El Rápido',
//       agency_logo_url: 'https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/marketplace%2F31%2F1%2Ftypes%2FlXRM1g5jljcGV1hgHQWFkf1Pdii2%2FLogo%20El%20R%C3%A1pido%20.png?alt=media&token=c0298da3-8b26-447e-8fe7-5dcb518fc97b',
//       reward_point_types: [
//         {
//           point_type_id: 'APP_ACTIONS',
//           name_es: 'Acciones en la Aplicación',
//           description: `<div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; background-color: #f4f4f4; padding: 1rem; box-sizing: border-box;">
//     <header style="background-color: #4CAF50; color: white; text-align: center; padding: 1rem; border-radius: 5px;">
//         <h1 style="font-size: clamp(1.5rem, 4vw, 2rem); margin: 0;">Earn Points with Travel Mode</h1>
//     </header>
//     <div style="background-color: white; border-radius: 5px; padding: 1rem; margin-top: 1rem; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
//         <h2 style="font-size: clamp(1.2rem, 3vw, 1.5rem); margin-bottom: 1rem;">How it works:</h2>
//         <ul style="list-style-type: none; padding: 0;">
//             <li style="margin-bottom: 0.75rem; padding-left: 1.5rem; position: relative; font-size: clamp(0.9rem, 2vw, 1rem);">
//                 <span style="position: absolute; left: 0; top: 0;">✈️</span>
//                 Enable Travel Mode in your app settings
//             </li>
//             <li style="margin-bottom: 0.75rem; padding-left: 1.5rem; position: relative; font-size: clamp(0.9rem, 2vw, 1rem);">
//                 <span style="position: absolute; left: 0; top: 0;">✈️</span>
//                 Earn points for every mile traveled
//             </li>
//             <li style="margin-bottom: 0.75rem; padding-left: 1.5rem; position: relative; font-size: clamp(0.9rem, 2vw, 1rem);">
//                 <span style="position: absolute; left: 0; top: 0;">✈️</span>
//                 Get bonus points for visiting new locations
//             </li>
//             <li style="margin-bottom: 0.75rem; padding-left: 1.5rem; position: relative; font-size: clamp(0.9rem, 2vw, 1rem);">
//                 <span style="position: absolute; left: 0; top: 0;">✈️</span>
//                 Share your travels to earn extra points
//             </li>
//             <li style="margin-bottom: 0.75rem; padding-left: 1.5rem; position: relative; font-size: clamp(0.9rem, 2vw, 1rem);">
//                 <span style="position: absolute; left: 0; top: 0;">✈️</span>
//                 Redeem your points for travel rewards
//             </li>
//         </ul>
//         <div style="display: flex; flex-wrap: wrap; justify-content: space-around; margin-top: 1.5rem; text-align: center;">
//             <div style="flex: 1 1 40%; padding: 0.5rem; background-color: #e9e9e9; border-radius: 5px; margin: 0.5rem; min-width: 100px; max-width: calc(50% - 1rem);">
//                 <div style="font-size: clamp(1.5rem, 4vw, 2rem); margin-bottom: 0.5rem;">🔛</div>
//                 <p style="font-size: clamp(0.8rem, 2vw, 0.9rem); margin: 0;">Enable Travel Mode</p>
//             </div>
//             <div style="flex: 1 1 40%; padding: 0.5rem; background-color: #e9e9e9; border-radius: 5px; margin: 0.5rem; min-width: 100px; max-width: calc(50% - 1rem);">
//                 <div style="font-size: clamp(1.5rem, 4vw, 2rem); margin-bottom: 0.5rem;">🏃</div>
//                 <p style="font-size: clamp(0.8rem, 2vw, 0.9rem); margin: 0;">Travel &amp; Explore</p>
//             </div>
//             <div style="flex: 1 1 40%; padding: 0.5rem; background-color: #e9e9e9; border-radius: 5px; margin: 0.5rem; min-width: 100px; max-width: calc(50% - 1rem);">
//                 <div style="font-size: clamp(1.5rem, 4vw, 2rem); margin-bottom: 0.5rem;">🏆</div>
//                 <p style="font-size: clamp(0.8rem, 2vw, 0.9rem); margin: 0;">Earn Points</p>
//             </div>
//             <div style="flex: 1 1 40%; padding: 0.5rem; background-color: #e9e9e9; border-radius: 5px; margin: 0.5rem; min-width: 100px; max-width: calc(50% - 1rem);">
//                 <div style="font-size: clamp(1.5rem, 4vw, 2rem); margin-bottom: 0.5rem;">🎁</div>
//                 <p style="font-size: clamp(0.8rem, 2vw, 0.9rem); margin: 0;">Get Rewards</p>
//             </div>
//         </div>
//     </div>
// </div>`
//         },
//         {
//           point_type_id: 'TICKETS',
//           name_es: 'Canje de Tickets',
//           description: '<!DOCTYPE html><html lang="es"><head><meta charset="UTF-8"><title>Canje de Tickets</title></head><body><h1>Canje de Tickets</h1></body></html>'
//         }
//       ],
//       validated_tickets_number: 0,
//       validated_tickets_fare_sum: 0,
//       reward_points: 0
//     },
//     {
//       id: 2,
//       agency_id: 'A00000007',
//       ticket_sample_picture_url: 'https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/MVPs%2FTickets%2Fticket_example_rapido.png?alt=media&token=2c1394e1-3b20-4c89-8f78-ef2e1adbc186',
//       ticket_valid_id_example: '123456',
//       ticket_valid_regex: '.\\d{6}.',
//       rewards_url: 'https://marketplace.ualabee.com/4-el-r%C3%A1pido-?city_id=31#el-r%C3%A1pido-&custom_token=$CUSTOM_TOKEN&agency_id=A00000006',
//       agency_name: 'El Lento',
//       agency_logo_url: 'https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/marketplace%2F31%2F1%2Ftypes%2FlXRM1g5jljcGV1hgHQWFkf1Pdii2%2FLogo%20El%20R%C3%A1pido%20.png?alt=media&token=c0298da3-8b26-447e-8fe7-5dcb518fc97b',
//       reward_point_types: [
//         {
//           point_type_id: 'APP_ACTIONS',
//           name_es: 'Acciones en la Aplicación',
//           description: '<!DOCTYPE html><html lang="es"><head><meta charset="UTF-8"><title>Acciones en la app</title></head><body><h1>Acciones en la app</h1></body></html>'
//         },
//         {
//           point_type_id: 'TICKETS',
//           name_es: 'Canje de Tickets',
//           description: '<!DOCTYPE html><html lang="es"><head><meta charset="UTF-8"><title>Canje de Tickets</title></head><body><h1>Canje de Tickets</h1></body></html>'
//         }
//       ],
//       validated_tickets_number: 0,
//       validated_tickets_fare_sum: 0,
//       reward_points: 0
//     }
//   ]
}
