import styled from 'styled-components/macro'
import { ArrowBack } from '@mui/icons-material'

export const StopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => !props.mobile ? '100%' : 'calc(100dvh - 68px)'};
  min-height: ${props => !props.mobile ? '100%' : 'calc(100dvh - 68px)'};
  overflow: hidden hidden;
  position: relative;
`

export const BackIcon = styled(ArrowBack)` 
    color: white;
`

export const Container = styled.div` 
    width: 100%; 
`

export const Icon = styled.img`
    width: 20px;
    height: 20px;   
`
