import { useEffect, useState } from 'react'
import { Box, Button, IconButton, Typography, useMediaQuery, Skeleton, Tabs, Tab, Badge } from '@mui/material'
import { Layout } from '../../uicomponents/layouts/Layout'
import { CustomMap } from '../../uicomponents/Map'
import { theme } from '../../theme/theme'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowBackRounded, Check, InfoRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { useDispatch, useSelector } from 'react-redux'
import { getTicketValidationUserStats } from '../../db/getTicketValidationUserStats'
import { REACT_APP_SHOW_LOGIN, REACT_APP_TICKETS_REWARDS_PROGRAM } from '../../constants/config'
import UserProfilePreview from '../Desktop/UserProfilePreview'
import { getCityConfig } from '../../db/getCityConfig'
import { setCurrentCityConfig } from '../../actions/ui'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { setUserTicketRewardStats } from '../../actions/user'
import { getCustomToken } from '../../db/auth/getCustomToken'
import { logEvent } from '../../firebase/firebase-config'
import { getAllRedeemedRewards } from '../../db/getAllRedeemedRewards'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded'
import { getAllUserActionPoints } from '../../db/getAllUserActionPoints'
import { getUserTicketValidations } from '../../db/getUserTicketValidations'

export default function RewardTickets () {
  const isMobile = useMediaQuery('(max-width:480px)')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector((state) => state.user.auth)
  const cityConfig = useSelector((state) => state.ui.cityConfig)
  const userTicketRewardStats = useSelector((state) => state.user.userTicketRewardStats)

  const searchParams = new URLSearchParams(window.location.search)

  const [faqOpen, setFaqOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tabs, setTabs] = useState('summary')
  const [activityHistory, setActivityHistory] = useState([])
  const [activeBadge, setActiveBadge] = useState(false)

  useEffect(() => {
    if (!REACT_APP_TICKETS_REWARDS_PROGRAM) return navigate('/')
    if (auth && auth?.isAnonymous && !searchParams.get('custom_token')) return navigate('/login')

    const cityId = searchParams.get('city_id')

    if (!cityConfig && cityId && auth) {
      getCityConfig(cityId)
        .then((response) => {
          dispatch(setCurrentCityConfig(response))
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [auth, cityConfig])

  useEffect(() => {
    setLoading(true)
    const cityId = searchParams.get('city_id')
    if ((cityConfig || cityId) && auth && !auth?.isAnonymous && !userTicketRewardStats) {
      getTicketValidationUserStats(cityConfig?.city_id || cityId)
        .then(async (response) => {
          const token = searchParams.get('custom_token') ?? await getCustomToken()
          response = response.map(item => {
            if (!item.rewards_url || !item.rewards_url.includes('$CUSTOM_TOKEN')) return item
            return {
              ...item,
              rewards_url: item.rewards_url.replace('$CUSTOM_TOKEN', token)
            }
          })
          dispatch(setUserTicketRewardStats(response))
          setLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    }
    if (userTicketRewardStats) setLoading(false)
  }, [cityConfig, auth])

  useEffect(() => {
    if (!userTicketRewardStats) return

    // make a promise all to get getClaimedRewards and getUserActionPoints, merge the results and set activityHistory
    Promise.all([getAllRedeemedRewards(cityConfig?.city_id), getAllUserActionPoints(cityConfig?.city_id), getUserTicketValidations(cityConfig?.city_id)])
      .then(([claimedRewards, userActionPoints, userValidations]) => {
        const mergedArray = [...claimedRewards, ...userActionPoints, ...userValidations.map(item => ({ ...item, action_point_type: { name: 'Carga de boleto' } }))]

        const addAgency = mergedArray.map((item) => {
          return {
            agency: { name: userTicketRewardStats.find(agency => agency.agency_id === (item.agency_id || item.action_point_type.agency_id)).agency_name },
            active: new Date(item.valid_until) > new Date(),
            ...item
          }
        })

        // sort by active and created_at
        addAgency.sort((a, b) => {
          if (a.active === b.active) {
            return new Date(b.created_at) - new Date(a.created_at)
          }
          return a.active ? -1 : 1
        })

        setActivityHistory(addAgency)
        setActiveBadge(addAgency.some(item => item.active))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [userTicketRewardStats])

  function createUrlWithQueryParams(baseUrl, params) {
    const query = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        query.append(key, value)
      }
    })
    return `${baseUrl}?${query.toString()}`
  }

  function handleClick(item) {
    const { agency_id: id } = item

    const eventParams = {
      lat: userPosition?.lat || null,
      lng: userPosition?.lng || null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_gender: userData?.gender || null
    }

    logEvent('ticket_rewards_add', eventParams)

    // Append the custom_token query param to the URL if it exists
    const baseUrl = '/rewards/tickets/validate'

    const params = {
      custom_token: searchParams.get('custom_token'),
      agency_id: id
    }

    if (!document.startViewTransition) {
      return navigate(createUrlWithQueryParams(baseUrl, params))
    }

    return document.startViewTransition(() => navigate(createUrlWithQueryParams(baseUrl, params)))
  }

  const handleBack = () => {
    if (!document.startViewTransition) {
      navigate(-1)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => navigate(-1))
  }

  const handleFAQOpen = () => {
    // log event
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null
    }

    logEvent('ticket_rewards_faq', eventParams)
    if (!document.startViewTransition) {
      setFaqOpen(!faqOpen)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => setFaqOpen(!faqOpen))
  }

  const handleRewardClick = (item) => {
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_gender: userData?.gender || null,
      cta_url: item.rewards_url,
      ticket_agency_id: item.id,
      agency_id: item.agency_id
    }

    const url = item.rewards_url

    logEvent('ticket_agency_rewards_cta', eventParams)
    url.replace('$CUSTOM_TOKEN', searchParams.get('custom_token'))

    window.open(url, '_self')
  }

  const handleAddPoints = (item) => {
    const { point_types: actionPointTypes } = item

    if (actionPointTypes.some(pointType => pointType.point_type_id === 'TICKETS')) {
      handleClick(item)
    } else {
      navigate(`/rewards/tickets/earn-options?agency_id=${item.agency_id}${searchParams.get('custom_token') ? `&custom_token=${searchParams.get('custom_token')}` : ''}`)
    }
  }

  function CustomTabPanel(props) {
    const { children, value, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== tabs}
        {...other}
        style={{ paddingBottom: '82px' }}
      >
        {value === tabs &&
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0',
              gap: '16px',
              overflow: 'auto'
            }}
          >
            {children}
          </Box>}
      </div>
    )
  }

  return (
    <Layout>
      <Box
        sx={{
          width: '450px',
          height: 'calc(100% - 32px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          zIndex: '430',
          backgroundColor: '#ffffff',
          borderRadius: '16px',
          boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px',
          position: 'absolute',
          top: '16px',
          left: '16px',
          padding: '16px',
          '@media (max-width:480px)': {
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            padding: 0
          }
        }}
      >

        <Box
          sx={{
            display: `${!searchParams.get('custom_token') ? 'flex' : 'none'}`,
            width: '100%',
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 8px',
            '@media (max-width:480px)': {
              backgroundColor: theme.palette.primary.main,
              padding: '10px 5px',
              alignItems: 'center'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row'
            }}
          >
            <IconButton
              onClick={handleBack}
            >
              <ArrowBackRounded
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />
            </IconButton>
            <Typography
              sx={{
                color: '#222831',
                fontSize: '16px',
                margin: '0 0 0 8px',
                '@media (max-width:480px)': {
                  color: '#fff',
                  margin: 0
                }
              }}
              variant='h1'
            >
              {!faqOpen && t('tickets_rewards_program.reward_tickets_page_title')}
              {faqOpen && t('tickets_rewards_program.faq_title')}
            </Typography>
          </Box>
          <IconButton
            id='faq_button'
            onClick={handleFAQOpen}
          >
            {!faqOpen &&
              <HelpRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}
            {faqOpen &&
              <ClearRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}

          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 10px',
            gap: '16px',
            overflow: 'auto'
          }}
        >
          {!faqOpen && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  fontWeight: '800',
                  margin: '0'
                }}
              >
                {t('tickets_rewards_program.reward_tickets_page_hero_title')}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '14px',
                  margin: '0'
                }}
              >
                {t('tickets_rewards_program.reward_tickets_page_hero_description')}
              </Typography>
            </Box>
          )}
          {!faqOpen && (
            <Tabs
              value={tabs}
              onChange={(e, value) => setTabs(value)}
              variant='fullWidth'
              TabIndicatorProps={{
                sx: {
                  display: 'none'
                }
              }}
              sx={{
                borderRadius: '50px',
                border: '1px solid rgba(70, 70, 70, 1)',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  minHeight: '48px',
                  fontWeight: 500
                },
                '& .Mui-selected': {
                  bgcolor: `${theme.palette.primary.main}3A`,
                  borderRadius: tabs === 'summary' ? '50px 0px 0px 50px' : '0px 50px 50px 0px'
                }
              }}
            >
              <Tab
                value='summary'
                icon={tabs === 'summary' && <Check />}
                iconPosition='start'
                label='Resumen'
                sx={{
                  borderRight: '0.5px solid rgba(70, 70, 70, 1)',
                  '&.Mui-selected': {
                    color: 'black'
                  }
                }}
              />
              <Tab
                icon={tabs === 'history' && <Check />}
                iconPosition='start'
                value='history'
                label={
                  <Badge
                    variant='dot'
                    color='error'
                    invisible={!activeBadge}
                    sx={{
                      '& .MuiBadge-dot': {
                        top: '-2px',
                        right: '-4px'
                      }
                    }}
                  >
                    Historial
                  </Badge>
                }
                sx={{
                  borderLeft: '0.5px solid rgba(70, 70, 70, 1)',
                  '&.Mui-selected': {
                    color: 'black'
                  }
                }}
              />
            </Tabs>
          )}
          {!faqOpen &&
            <CustomTabPanel
              value='summary'
            >

              {!faqOpen && userTicketRewardStats && userTicketRewardStats.map((item, index) => {
                const { agency_logo_url: logo, agency_name: agencyName, reward_points: rewardPoints } = item
                return (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#EFEFEF',
                      padding: '16px',
                      borderRadius: '8px',
                      gap: '8px'
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '8px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px'
                        }}
                      >
                        <img
                          src={logo}
                          alt='placeholder'
                          style={{
                            width: '50px',
                            height: 'auto'
                          }}
                        />
                        <Typography
                          sx={{
                            color: '#222831',
                            fontSize: '12px',
                            fontWeight: '800',
                            margin: '0'
                          }}
                        >
                          {agencyName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '4px',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#222831',
                            fontSize: '40px',
                            fontWeight: '800',
                            margin: '0'
                          }}
                        >
                          {rewardPoints}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#222831',
                            fontSize: '12px',
                            margin: '0'
                          }}
                        >
                          {t('tickets_rewards_program.points')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '8px',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Button
                        variant='outlined'
                        onClick={() => handleRewardClick(item)}
                        sx={{
                          color: theme.palette.primary.main,
                          padding: '10px 20px',
                          borderRadius: '14px',
                          fontSize: '14px',
                          fontWeight: '800',
                          cursor: 'pointer',
                          gap: '4px'
                        }}
                      >
                        <ShoppingBagRoundedIcon />
                        {t('tickets_rewards_program.redeem_points')}
                      </Button>
                      <Button
                        onClick={() => handleAddPoints(item)}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: '#fff',
                          padding: '10px 20px',
                          borderRadius: '14px',
                          fontSize: '14px',
                          fontWeight: '800',
                          cursor: 'pointer',
                          gap: '4px',
                          '&:hover': {
                            backgroundColor: theme.palette.primary.main
                          }
                        }}
                      >
                        <AddCircleRoundedIcon />
                        {t('tickets_rewards_program.add_points')}
                      </Button>
                    </Box>

                  </Box>
                )
              }
              )}
              {!faqOpen && loading && !userTicketRewardStats && (
                <>
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height='120px'
                    sx={{
                      borderRadius: '8px'
                    }}
                  />
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height='120px'
                    sx={{
                      borderRadius: '8px'
                    }}
                  />
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height='120px'
                    sx={{
                      borderRadius: '8px'
                    }}
                  />
                </>
              )}
            </CustomTabPanel>}
          {!faqOpen &&
            <CustomTabPanel
              value='history'
            >
              {activityHistory?.length > 0 && activityHistory?.map((item, index) => {
                const { active, reward_short_id: rewardShortId, created_at: createdAt, agency, reward_url: rewardUrl, points, action_point_type: actionPointType, timestamp } = item
                return (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#EFEFEF',
                      padding: '16px',
                      borderRadius: '8px',
                      gap: '8px',
                      border: `${active ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent'}`
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '8px'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#222831',
                          fontSize: '12px',
                          fontWeight: '800',
                          margin: '0'
                        }}
                      >
                        {/* {agencyName} */}
                        {agency.name}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '16px'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#222831',
                            fontSize: '12px',
                            margin: '0'
                          }}
                        >
                          {/* {validatedTicketsNumber} {t('tickets_rewards_program.uploaded_tickets')} */}
                          {new Date(createdAt || timestamp).toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#222831',
                          fontSize: '12px',
                          margin: '0',
                          textTransform: 'uppercase'
                        }}
                      >
                        {rewardShortId || actionPointType?.name}
                      </Typography>
                      {!active &&
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {!points &&
                            <InfoRounded
                              sx={{
                                color: '#A6A9AC',
                                width: '16px',
                                height: '16px'
                              }}
                            />}
                          <Typography
                            sx={{
                              color: '#222831',
                              fontSize: '12px',
                              margin: '0'
                            }}
                          >
                            {points ? `+${points} ${t('tickets_rewards_program.points')}` : t('tickets_rewards_program.redeemed_coupon')}
                          </Typography>
                        </Box>}
                    </Box>
                    {active &&
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <InfoRounded
                            sx={{
                              color: theme.palette.primary.main,
                              width: '16px',
                              height: '16px'
                            }}
                          />
                          <Typography
                            sx={{
                              color: '#222831',
                              fontSize: '12px',
                              margin: '0'
                            }}
                          >
                            {t('tickets_rewards_program.active_coupon')}
                          </Typography>
                        </Box>
                        <Link
                          to={rewardUrl}
                        >
                          <Button
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                              padding: '4px 16px',
                              borderRadius: '14px',
                              fontSize: '14px',
                              fontWeight: '800',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: theme.palette.primary.main
                              }
                            }}
                          >
                            {t('tickets_rewards_program.see_active')}
                          </Button>
                        </Link>
                      </Box>}
                  </Box>
                )
              })}
              {activityHistory?.length === 0 &&
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '0',
                    padding: '0'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      textAlign: 'center',
                      margin: '0',
                      color: theme.text.secondary.main
                    }}
                  >
                    No haz canjeado ninguna recompensa aún
                  </Typography>
                </Box>}
            </CustomTabPanel>}
          {faqOpen && <FAQSection open={faqOpen} t={t} />}
        </Box>
      </Box>
      {!isMobile && (
        <CustomMap
          showDraggableDestinationMarkers={false}
        />
      )}
      {!isMobile && REACT_APP_SHOW_LOGIN &&
        <UserProfilePreview />}
    </Layout>
  )
}

export const FAQSection = ({ open, t }) => {
  return (
    <ul
      style={{
        display: `${open ? 'block' : 'none'}`
      }}
    >
      <li
        style={{
          fontWeight: '800'
        }}
      >
        {t('tickets_rewards_program.faqs.question_1')}
      </li>
      <p>
        {t('tickets_rewards_program.faqs.answer_1')}
      </p>
      <li
        style={{
          fontWeight: '800'
        }}
      >
        {t('tickets_rewards_program.faqs.question_2')}
      </li>
      <p>
        {t('tickets_rewards_program.faqs.answer_2')}
      </p>
      <li
        style={{
          fontWeight: '800'
        }}
      >
        {t('tickets_rewards_program.faqs.question_3')}
      </li>
      <p>
        {t('tickets_rewards_program.faqs.answer_3')}
      </p>
      <li
        style={{
          fontWeight: '800'
        }}
      >
        {t('tickets_rewards_program.faqs.question_4')}
      </li>
      <p>
        {t('tickets_rewards_program.faqs.answer_4')}
      </p>
    </ul>
  )
}
