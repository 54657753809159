import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST, REACT_APP_VARIANT_ID } from '../constants/config'

export const getAllUserActionPoints = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': REACT_APP_VARIANT_ID,
      'Accept-Language': navigator.language
    }
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/rewards/tickets/getAllUserActionPoints?cityId=${cityId}`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting user action points')
      }
    })
}
