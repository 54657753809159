import styled from 'styled-components/macro'
import { RefreshRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const ArrivalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  height: ${props => props?.showETA ? 'calc(100% - 268px)' : 'calc(100% - 207px)'};
  @media (max-width: 480px) {
    height: ${props => props?.showETA ? 'calc(100% - 336px)' : 'calc(100% - 282px)'};
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 18px;
  border-bottom: 1px solid #E8EDEE;
  width: 100%;
`

export const Title = styled.p`
  margin: 0;
  font-size: 16px;
  color: #222831;
`

export const RefreshButton = styled(IconButton)`
  padding: 6px;
`

export const RefreshIcon = styled(RefreshRounded)`
`

export const Container = styled.div`
  width: 100%;
  padding-bottom: 50px;
  height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 550px)' : 'calc(100vh - 470px)' : props?.showETA ? 'calc(100vh - 490px)' : 'calc(100vh - 410px)'};
  min-height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 550px)' : 'calc(100vh - 470px)' : props?.showETA ? 'calc(100vh - 490px)' : 'calc(100vh - 410px)'};
  overflow: auto;
  max-height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 550px)' : 'calc(100vh - 470px)' : props?.showETA ? 'calc(100vh - 490px)' : 'calc(100vh - 410px)'};
  @media (max-width: 480px) {
    height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 564px)' : 'calc(100vh - 420px - 52px)' : props?.showETA ? 'calc(100vh - 440px - 64px)' : 'calc(100vh - 420px)'};
    min-height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 564px)' : 'calc(100vh - 420px - 52px)' : props?.showETA ? 'calc(100vh - 440px - 64px)' : 'calc(100vh - 420px)'};
    max-height: ${props => props?.showBar ? props?.showETA ? 'calc(100vh - 564px)' : 'calc(100vh - 420px - 52px)' : props?.showETA ? 'calc(100vh - 440px - 64px)' : 'calc(100vh - 420px)'};
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
