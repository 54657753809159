import React, { useEffect, useState } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import CityInfo from '../components/CityInfo'
import Lines from '../components/Lines'
import Line from '../components/Line'
import Stop from '../components/Stop'
import SearchAutocomplete from '../components/SearchAutocomplete'
import DestinationPreview from '../components/DestinationPreview'
import SearchResults from '../components/SearchResults'
import { ResultsRoutesAndTrajectory } from '../components/ResultsRoutesAndTrajectory'
import CovidCenters from '../components/Covid/CovidCenters'
import Login from '../components/Login'
import { LandingPage } from '../components/LandingPage'
import UserProfile from '../components/UserProfile'
import { setUserPosition, startLogin } from '../actions/user'
import { useDispatch, useSelector } from 'react-redux'
import NoLoginDialog from '../uicomponents/NoLoginDialog'
import { ChooseFromMap } from '../components/ChooseFromMap'
import { Alert } from '../uicomponents/ui'
import {
  setAutocompleteStations,
  setIsIosApp,
  setLoading,
  setOpenBottomSmartBanner,
  setOpenSmartBanner,
  setOpenTopSmartBanner,
  setShowETA
} from '../actions/ui'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from '../firebase/firebase-config'
import { SkeletonApp } from '../uicomponents/SkeletonApp'
import { InvitationMessage } from '../components/InvitationMessage'
import { Ad } from '../uicomponents/ui/Ad'
import Home from '../components/Home'
import StopTimes from '../components/StopTimes'
import { REACT_APP_SHOW_CAPTIVE_PORTAL, REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS } from '../constants/config'
import { getStopsByRouteType } from '../db/getStopsByRouteType'
import { getTripsByRouteType } from '../db/getTripsByRouteType'
import PolylineUtil from 'polyline-encoded'
import { setSubwayAndTrainNetworks } from '../actions/map'
import NPSDialog from '../uicomponents/NPSDialog'
import { useFont } from '../contexts/FontContext'
import RewardTickets from '../components/RewardTickets'
import RewardTicketsValidate from '../components/RewardTicketsValidate'
import PageView from '../components/PageView'
import EarnOptions from '../components/EarnOptions'

// Define your routes
const routes = [
  { path: '/', element: <><Home /><PageView /></> },
  { path: '/news', element: <><Home /><PageView /></> },
  { path: '/city_info', element: <><CityInfo /><PageView /></> },
  { path: '/city_info/:cityId/:id', element: <><CityInfo /><PageView /></> },
  { path: '/news/:id', element: <><Home /><PageView /></> },
  { path: '/user_reports/:cityId/:reportId', element: <><Home /><PageView /></> },
  { path: '/lines', element: <><Lines /><PageView /></> },
  { path: '/routes/:cityId/:lineId', element: <><Line /><PageView /></> },
  { path: '/routes/:cityId/:lineId/:routeId', element: <><Line /><PageView /></> },
  { path: '/stops/:cityId/:stopCode', element: <><Stop /><PageView /></> },
  { path: '/stop_times/:cityId/:shapeid/:stopid', element: <><StopTimes /><PageView /></> },
  { path: '/search', element: <><SearchAutocomplete /><PageView /></> },
  { path: '/destination-preview', element: <><DestinationPreview /><PageView /></> },
  { path: '/trip_share', element: <><SearchResults /><PageView /></> },
  { path: '/results-routes-and-trajectory', element: <><ResultsRoutesAndTrajectory /><PageView /></> },
  { path: '/covid', element: <><CovidCenters /><PageView /></> },
  { path: '/covid/:id', element: <><CovidCenters /><PageView /></> },
  { path: '/login', element: <><Login /><PageView /></> },
  { path: '/deep_links', element: <><LandingPage /><PageView /></> },
  { path: '/user/:uid', element: <><UserProfile /><PageView /></> },
  { path: '/choose-from-map', element: <><ChooseFromMap /><PageView /></> },
  { path: '/search/choose-from-map', element: <><ChooseFromMap /><PageView /></> },
  { path: '/rewards/tickets', element: <><RewardTickets /><PageView /></> },
  { path: '/rewards/tickets/validate', element: <><RewardTicketsValidate /><PageView /></> },
  { path: '/rewards/tickets/earn-options', element: <><EarnOptions /><PageView /></> }
]

// Create the router
const router = createBrowserRouter(routes)

export default router

// Use the above-defined router in your component
export const AppRouter = () => {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const isIosApp = navigator.userAgent.includes('UalabeeLite=true') || window.location.search.includes('UalabeeLite=true') || sessionStorage.getItem('UalabeeLite')
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    dispatch(startLogin()).then(() => {
      if (REACT_APP_SHOW_CAPTIVE_PORTAL && !isIosApp && isMobile) {
        dispatch(setLoading(true))
        setFetching(true)

        fetchAndActivate(remoteConfig)
          .then(() => {
            const { _value: showCaptivePortal } = getValue(remoteConfig, 'show_captive_portal')
            dispatch(setOpenSmartBanner(showCaptivePortal === 'true'))

            if (showCaptivePortal === 'true') {
              setFetching(false)
              dispatch(setLoading(false))
            }

            const { _value: showTopBanner } = getValue(remoteConfig, 'show_smart_banner_top')
            dispatch(setOpenTopSmartBanner(showTopBanner === 'true'))
            const { _value: showBottomBanner } = getValue(remoteConfig, 'show_smart_banner_bottom')
            dispatch(setOpenBottomSmartBanner(showBottomBanner === 'true'))

            setFetching(false)
            dispatch(setLoading(false))
          })
          .catch((e) => {
            setFetching(false)
            dispatch(setLoading(false))
            console.error('Error fetching and activating remote config', e)
          })
      } else {
        setFetching(false)
      }
    })

    const currentShowETA = localStorage.getItem('show_arrival_time_information') ? JSON.parse(localStorage.getItem('show_arrival_time_information')) : true
    dispatch(setShowETA(currentShowETA))
  }, [])

  useEffect(() => {
    const ualabeeLite = navigator.userAgent.includes('UalabeeLite=true') || window.location.search.includes('UalabeeLite=true') || sessionStorage.getItem('UalabeeLite')

    if (ualabeeLite) {
      dispatch(setIsIosApp(true))
      sessionStorage.setItem('UalabeeLite', true)
    }
  }, [navigator.userAgent])

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const userPosition = {
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude
          }
          dispatch(setUserPosition(userPosition))
        },
        (err) => {
          console.error(`ERROR(${err.code}): ${err.message}`)
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        }
      )
    } else {
      console.error('Browser does not support geolocation!')
    }
  }, [])

  useEffect(() => {
    if (!cityConfig) return
    const { city_id: id, transport_types: transportTypes } = cityConfig

    if (!transportTypes.some(type => type.route_type === 1 || type.route_type === 2)) {
      dispatch(setAutocompleteStations([]))
      return
    }

    const params = {
      city_id: id,
      route_types: transportTypes.filter(type => type.route_type === 1 || type.route_type === 2).map(type => type.route_type)
    }
    getStopsByRouteType(params).then((stops) => {
      dispatch(setAutocompleteStations(stops))
    })

    if (REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS) {
      getTripsByRouteType(params).then((trips) => {
        const cleanTrips = trips.map(type => {
          const { route_type: routeType } = type
          const { trips } = type
          return trips.map(trip => {
            return {
              ...trip,
              route_type: routeType,
              polylineDecoded: PolylineUtil.decode(trip.shape_polyline)
            }
          })
        }).flat()

        const payload = {
          enabled: true,
          trips: cleanTrips
        }

        dispatch(setSubwayAndTrainNetworks(payload))
      })
    }
  }, [cityConfig])

  if (fetching) {
    return <SkeletonApp />
  }

  const fontFamily = useFont()

  return (
    <RouterProvider router={router}>  {/* Use RouterProvider with the router */}
      <div
        style={{
          width: '100vw',
          height: '100vh',
          maxHeight: '100vh',
          fontFamily
        }}
      >
        <NoLoginDialog />
        <Alert />
        <Ad />
        <InvitationMessage />
        <NPSDialog />
      </div>
    </RouterProvider>
  )
}
